import {
    addCenterNotification, addNotification, setCategories, setCenterNotifications, setConfig, setListMetadata, setMetadata, setNotificationBadgeContent, setNotifications, setPreFetchStatus,
    setSelectedNotification
} from './actions/NonContextualNotifications';
import { default_cat, URLS } from './utils';

class NotificationsProvider {

    constructor() {
        this.websocketsData = new Map(); // key: wsId value {url: wsUrl, errors: 0}
        this.data = {
            wsConnections : [],
            wsErrorThreshold : 10
        };
    }

    /**
     * Fetch notification config from content server
     * If startWsConnection is passed (true by default) it will start the websockets connection present in the notification config
     *
     * @param {boolean} startWsConnection - if true will start websockets present on the config
     * @param fetchHttpData
     */
    initNotifications(startWsConnection = true, fetchHttpData = true) {
        //Get the notifications config
        this.httpClient.request('get', this.store.getState().shell.contentUrl + URLS.config).then(request => {
            this.config = request.data;
            this.store.dispatch(setConfig(request.data));
            //TODO: Add entitlements verifier to both the connection
            if (startWsConnection) {
                //Set websocket events handler
                let events = new Map();
                events.set('onopen', this.onOpen);
                events.set('onmessage', this.onReceive);
                events.set('onclose', this.onClose);
                events.set('onerror', this.onError);
                //Init connections
                let baseUrl = this.store.getState().shell.hostPort;
                baseUrl = baseUrl.replace(window.location.protocol === 'https:' ? 'https://' : 'http://', window.location.protocol === 'https:' ? 'wss://' : 'ws://');
                request.data.wsConnections.forEach(socketUrl => {
                    let connectResult = this.realTimeClient.connect(baseUrl + socketUrl + '/' + this.store.getState().shell.currentLoggedUser.id, undefined, events);
                });
            }
            if (fetchHttpData) {
                // noinspection JSCheckFunctionSignatures
                Promise.all([
                    this.getCategories(),
                    this.getMetadata(),
                    this.get({}, true, true),
                ]).then(() => {
                    this.store.dispatch(setPreFetchStatus(true, false));
                }).catch(e => {
                    console.error('Error Fetching notifications', e);
                    this.store.dispatch(setPreFetchStatus(true, true));
                });

            }
        }).catch(err => {
            console.error('No notification config found', err);
        });
    }

    /*========================================================= Websockets event handler ================================================*/

    onReceive = (wsId, event) => {
        let data = {};
        try {
            data = JSON.parse(event.data);
        } catch ( e ) {
            console.error('malformed notification');
        }
        if (data !== {}) {
            data.createdAt = new Date();

            let metadataClone = JSON.parse(JSON.stringify(this.store.getState().notificationService.NonContextualNotifications.metadata));
            let totalMeta = metadataClone.find(e => e.name === 'total');
            totalMeta.total = totalMeta.total + 1;
            totalMeta.unread = totalMeta.unread + 1;
            data.categories.forEach(e => {
                let catMeta = metadataClone.find(j => j.id === e.id);
                catMeta.total = catMeta.total + 1;
                catMeta.unread = catMeta.unread + 1;
            });

            this.store.dispatch(setMetadata(metadataClone));
            this.store.dispatch(setListMetadata(metadataClone));
            this.store.dispatch(addNotification(data, true, true));
            this.store.dispatch(setNotificationBadgeContent(this.store.getState().notificationService.NonContextualNotifications.notificationBadgeContent.concat([data])));

            let { keywords, selectedCategory, startDate, endDate, isReadFilter } = this.store.getState().notificationService.NonContextualNotifications;
            if ((selectedCategory === 'total' || selectedCategory === data.categoryId) && !keywords && !startDate && !endDate && !isReadFilter) {
                this.store.dispatch(addCenterNotification(data, true));
            }
        }

    };

    onClose = (wsId, CloseEvent) => {
        console.log('websocket close: ', CloseEvent);
        this.websocketsData.delete(wsId);
    };

    onError = (wsId, event) => {
        console.log('Websocket error', event);
        let websocketData = this.websocketsData.get(wsId);
        if (!websocketData) {
            console.error('Notification websocket not register');
            return;
        }
        if (websocketData.errors >= this.data.wsErrorThreshold) {
            console.warn('Notification error threshold hit for: ' + websocketData.url);
            const closeResult = this.close(wsId);
            //Check for different true, because error will be a string which is also true
            if (closeResult !== true)
                console.error('Failed to close websocket: ', closeResult);
        }
    };

    onOpen = (wsId, event) => {
        this.websocketsData.set(wsId, { url : event.target.url, errors : 0 });
    };

    /**
     * Method to explicitly close a source of notifications.
     * The source of notifications may be a ws or http connection.
     * If the source is an ws connection it will close the socket, otherwise it will only stop making get requests.
     * @param {String} wsId - Key that identifies the external source
     * @param {Function} callback - Callback functions to call once the close method is successfull
     */
    close = (wsId, callback) => {
        let closeResult = this.realTimeClient.close(wsId);
        callback && callback(closeResult);
        return closeResult;
    };

    /*========================================================= Http connection handler ================================================*/

    /**
     *This method will retrieve all the notification categories and set them in the reducer.
     *
     * @return {promise} returns a promise that resolves if the fetch is successful or fails if it isn't
     */
    getCategories = () => {
        return new Promise((resolve, reject) => {
            let shellStore = this.store.getState().shell;
            let baseUrl = shellStore.hostPort;
            this.httpClient.request('get', baseUrl + URLS.categories).then((request) => {
                if (Array.isArray(request.data)) {
                    let sortedCats = request.data.filter(e => e.name !== 'success' && e.name !== 'error' && e.name !== 'info' && e.name !== 'warning');
                    let errorCat = request.data.find(e => e.name === 'error');
                    let warningCat = request.data.find(e => e.name === 'warning');
                    let infoCat = request.data.find(e => e.name === 'info');
                    let successCat = request.data.find(e => e.name === 'success');
                    sortedCats.unshift(default_cat);
                    if (successCat)
                        sortedCats.unshift(successCat);
                    if (infoCat) {
                        sortedCats.unshift(infoCat);
                    }
                    if (warningCat) {
                        sortedCats.unshift(warningCat);
                    }
                    if (errorCat) {
                        sortedCats.unshift(errorCat);
                    }

                    this.store.dispatch(setCategories(sortedCats));
                } else {
                    this.store.dispatch(setCategories(request.data));
                }
                resolve();
            }).catch((e) => reject(e));
        });
    };

    /**
     * Method that retrieves:
     * - The total number o notifications
     * - The total number of unread notification
     * From all the categories and sets it in the store.
     *
     * @param {String} filter.keyword - Keywords text to filter the notifications
     * @param {Date} filter.beginDate - Notification should be created after this date
     * @param {Date} filter.endDate - Notification should be created before this date
     * * @param {Date} filter.status - Notification should be of this category
     *
     * @return {promise} returns a promise that resolves if the fetch is successful or fails if it isn't
     */
    getMetadata = (filter = {}, resetLoading = false) => {
        const { keyword, beginDate, endDate, status } = filter;
        return new Promise((resolve, reject) => {
            let requestParams = {
                keyword,
                beginDate : beginDate ? this.parseDate(beginDate) : undefined,
                endDate : endDate ? this.parseDate(endDate) : undefined,
                status
            };
            let shellStore = this.store.getState().shell;
            let baseUrl = shellStore.hostPort;

            this.httpClient.request('get', baseUrl + URLS.metadata, undefined, requestParams).then((request) => {
                this.store.dispatch(setMetadata(request.data, resetLoading));
                if (!keyword && !beginDate && !endDate && status === undefined) {
                    this.store.dispatch(setListMetadata(request.data));
                }
                resolve();
            }).catch((e) => reject(e));
        });
    };

    parseDate = date => {
        let test = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()} ${date.toGMTString().slice(date.toGMTString().indexOf(date.getUTCFullYear()) + 5, date.toGMTString().indexOf('GM') - 1)}0`;
        return test;
    };

    /**
     * Method to retrieve notifications from the http connection
     * It is possible to send filters to the search.
     *
     * Save a map entry to the reducer where the request is done.
     *
     * All the following args should be inside an object passed to this function
     * @param {object} filter - Identifier of category to filter
     * @param {String} filter.categoryId - Identifier of category to filter
     * @param {String} filter.keywords - Keywords text to filter the notifications
     * @param {Date} filter.startDate - Start date of the notifications
     * @param {Date} filter.endDate - End date of the notifications
     * @param {Boolean} filter.isRead - Filter the notifications based on the "read" value (if the notification is already read or not)
     * @param {number} filter.page - page wanted and number of records for each page
     * @param {number} filter.itemsPerPage - page wanted and number of records for each page
     * @param {boolean} userNotifications - sets data to notification var
     * @param {boolean} useCenterNotifications - sets data to centerNotifications var
     * @param {boolean} resetData - sets instead of adding and sets loadContent to false
     *
     * @return {promise} returns a promise that resolves if the fetch is successful or fails if it isn't
     */
    get(filter = {}, userNotifications = false, useCenterNotifications = false, resetData = false) {
        let { categoryId, keywords, startDate, endDate, isRead, page = 1, itemsPerPage = 20 } = filter;
        return new Promise((resolve, reject) => {
            let shellStore = this.store.getState().shell;
            let requestParams = useCenterNotifications ?
                {
                    category : categoryId,
                    keyword : keywords,
                    beginDate : startDate ? this.parseDate(startDate) : undefined,
                    endDate : endDate ? this.parseDate(endDate) : undefined,
                    status : isRead,
                    itemsPerPage,
                    pageNumber : page,
                    noContent : false
                } :
                { itemsPerPage, pageNumber : page, noContent : false };
            let baseUrl = shellStore.hostPort;
            this.httpClient.request('get', baseUrl + URLS.notifications, undefined, requestParams).then((request) => {
                if (resetData) {
                    if (userNotifications) {
                        this.store.dispatch(setNotifications(request.data, true));
                    }
                    if (useCenterNotifications) {
                        this.store.dispatch(setCenterNotifications(request.data, true));
                    }
                } else {
                    if (userNotifications) {
                        this.store.dispatch(addNotification(request.data));
                    }
                    if (useCenterNotifications) {
                        this.store.dispatch(addCenterNotification(request.data));
                    }
                }
                resolve();
            }).catch(e => {
                console.log('Error fetching notifications', e);
                reject();
            });
        });
    };

    /**
     * Method to set a notification as read.
     * @param {*} id - Identifier of the notification
     */
    markRead = (id) => {
        return new Promise((resolve, reject) => {
            let shellStore = this.store.getState().shell;
            let baseUrl = shellStore.hostPort;
            this.httpClient.request('put', baseUrl + URLS.notifications + '/' + id, undefined, { operation : 'read' }, [1, 2, 3]).then((request) => {
                let categories = [];
                let newCenterNotifications = this.store.getState().notificationService.NonContextualNotifications.centerNotifications.map(e => {
                    if (e.id === id) {
                        categories = e.categories;
                        return { ...e, readAt : new Date() };
                    } else {
                        return e;
                    }
                });
                let newNotifications = this.store.getState().notificationService.NonContextualNotifications.notifications.map(e => {
                    if (e.id === id) {
                        return { ...e, readAt : new Date() };
                    } else {
                        return e;
                    }
                });
                let newMetadata = this.store.getState().notificationService.NonContextualNotifications.metadata.map(metadata => {
                    if (categories.length === 0 && metadata.name === 'unknown') {
                        return { ...metadata, unread : metadata.unread - 1 };
                    }
                    if (categories.find(cat => cat.id === metadata.id) || metadata.name === 'total') {
                        return { ...metadata, unread : metadata.unread - 1 };

                    }
                    return metadata;
                });
                let selectedNot = this.store.getState().notificationService.NonContextualNotifications.selectedNotification;
                if (selectedNot && selectedNot.id === id) {
                    this.store.dispatch(setSelectedNotification({ ...selectedNot, readAt : new Date() }));
                }
                this.store.dispatch(setMetadata(newMetadata));
                this.store.dispatch(setCenterNotifications(newCenterNotifications));
                this.store.dispatch(setNotifications(newNotifications));
            }).catch(e => {
                console.log('Error fetching notifications', e);
                reject();
            });
        });
    };

    /**
     * Method to set all notifications as read.
     * If a category is sent as argument, it will only mark the notifications of that category as read.
     * @param {*} category - Identifier of the category filter
     */
    markAllRead = (category) => {
        //Not implemented yet
    };

    /**
     * Method to set a notification as unread.
     * @param {*} id - Identifier of the notification
     */
    markUnread = (id) => {
        return new Promise((resolve, reject) => {
            let shellStore = this.store.getState().shell;
            let baseUrl = shellStore.hostPort;
            this.httpClient.request('put', baseUrl + URLS.notifications + '/' + id, undefined, { operation : 'unread' }, [1, 2, 3]).then((request) => {
                let categories = [];
                let newCenterNotifications = this.store.getState().notificationService.NonContextualNotifications.centerNotifications.map(e => {
                    if (e.id === id) {
                        categories = e.categories;
                        return { ...e, readAt : undefined };
                    } else {
                        return e;
                    }
                });
                let newNotifications = this.store.getState().notificationService.NonContextualNotifications.notifications.map(e => {
                    if (e.id === id) {
                        return { ...e, readAt : undefined };
                    } else {
                        return e;
                    }
                });
                let newMetadata = this.store.getState().notificationService.NonContextualNotifications.metadata.map(metadata => {
                    if (categories.length === 0 && metadata.name === 'unknown') {
                        return { ...metadata, unread : metadata.unread + 1 };
                    }
                    if (categories.find(cat => cat.id === metadata.id) || metadata.name === 'total') {
                        return { ...metadata, unread : metadata.unread + 1 };
                    }
                    return metadata;
                });
                let selectedNot = this.store.getState().notificationService.NonContextualNotifications.selectedNotification;
                if (selectedNot && selectedNot.id === id) {
                    this.store.dispatch(setSelectedNotification({ ...selectedNot, readAt : undefined }));
                }
                this.store.dispatch(setMetadata(newMetadata));
                this.store.dispatch(setCenterNotifications(newCenterNotifications));
                this.store.dispatch(setNotifications(newNotifications));
            }).catch(e => {
                console.log('Error fetching notifications', e);
                reject();
            });
        });
    };

    /**
     * Method to delete a notification
     * @param {*} id - Identifier of the notification
     */
    delete = (id) => {
        return new Promise((resolve, reject) => {
            let shellStore = this.store.getState().shell;
            let baseUrl = shellStore.hostPort;
            this.httpClient.request('delete', baseUrl + URLS.notifications + '/' + id,).then((request) => {
                let categories = [];
                let isRead = false;
                let newCenterNotifications = this.store.getState().notificationService.NonContextualNotifications.centerNotifications.filter(e => {
                    if (e.id === id) {
                        categories = e.categories;
                        isRead = !!e.readAt;
                    }
                    return e.id !== id;
                });
                let newNotifications = this.store.getState().notificationService.NonContextualNotifications.notifications.filter(e => {
                    return e.id !== id;
                });
                let newMetadata = this.store.getState().notificationService.NonContextualNotifications.metadata.map(metadata => {
                    if (categories.length === 0 && metadata.name === 'unknown') {
                        return { ...metadata, unread : isRead ? metadata.unread : metadata.unread - 1, total : metadata.total - 1 };
                    }
                    if (categories.find(cat => cat.id === metadata.id) || metadata.name === 'total') {
                        return { ...metadata, unread : isRead ? metadata.unread : metadata.unread - 1, total : metadata.total - 1, };
                    } else {
                        return metadata;
                    }
                });
                let newListMetadata = this.store.getState().notificationService.NonContextualNotifications.listMetadata.map(metadata => {
                    if (categories.length === 0 && metadata.name === 'unknown') {
                        return { ...metadata, unread : isRead ? metadata.unread : metadata.unread - 1, total : metadata.total - 1 };
                    }
                    if (categories.find(cat => cat.id === metadata.id) || metadata.name === 'total') {
                        return { ...metadata, unread : isRead ? metadata.unread : metadata.unread - 1, total : metadata.total - 1 };
                    }
                    return metadata;
                });
                this.store.dispatch(setMetadata(newMetadata));
                this.store.dispatch(setListMetadata(newListMetadata));
                this.store.dispatch(setCenterNotifications(newCenterNotifications));
                this.store.dispatch(setNotifications(newNotifications));
                let selectedNot = this.store.getState().notificationService.NonContextualNotifications.selectedNotification;
                if (selectedNot && selectedNot.id === id) {
                    if (newCenterNotifications[0]) {
                        this.store.dispatch(setSelectedNotification(newCenterNotifications[0], undefined, this.store.getState().shell.services.Notifications));
                    } else {
                        this.store.dispatch(setSelectedNotification(undefined));
                    }
                }
            }).catch(e => {
                console.log('Error fetching notifications', e);
                reject();
            });
        });
    };

    /**
     * Method to delete all notifications.
     * If a category is sent as argument, it will only delete the notifications of that category.
     * @param {*} category - Identifier of the category filter
     */
    deleteAll = (category) => {
        //Not implemented yet
    };

    /* ================================================ Setters =======================================================================*/

    /**
     * Method to set the httpclient used to retrieve the notifications from external sources that consist in http requests
     * @param {Object} httpClient - Http Client
     */
    setHttpClient = (httpClient) => {
        this.httpClient = httpClient;
    };

    /**
     * Method to set the RealTime client used to retrieve the notification from external source that consist in ws connections
     * @param {Object} realTimeClient - Real Time Client
     */
    setRealTimeClient = (realTimeClient) => {
        this.realTimeClient = realTimeClient;
    };

    /**
     * Method to set the project store
     * @param {Object} store - Project store
     */
    setStore = (store) => {
        this.store = store;
    };

}

const notificationsProvider = new NotificationsProvider();
export default notificationsProvider;
