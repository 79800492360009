export const URLS = {
    config : '/foundation/v3/micro-modules/omni-notifications/config.json',
    categories : "/bin/mvc.do/ufe/v3/categories",
    metadata: "/bin/mvc.do/ufe/v3/notifications/metadata",
    notifications :"/bin/mvc.do/ufe/v3/notifications",
    logging : false,
};

export const default_cat = {
    id : '-1',
    name : 'unknown',
    icon : 'icon-notifications',
};