/* ================================ NOTIFICATION - Config ============================================*/

export const SET_CONFIG = 'SET_CONFIG';

export function setConfig(config) {
    return { type : SET_CONFIG, config };
}

/* ================================ NOTIFICATION - Logic ============================================*/

export const SET_CATEGORIES = 'SET_CATEGORIES';

export function setCategories(categories) {
    return { type : SET_CATEGORIES, categories };
}

export const SET_METADATA = 'SET_METADATA';

export function setMetadata(metadata, stopLoading) {
    return { type : SET_METADATA, metadata, stopLoading };
}

export const SET_LIST_METADATA = 'SET_LIST_METADATA';

export function setListMetadata(metadata) {
    return { type : SET_LIST_METADATA, metadata };
}

export const ADD_NOTIFICATIONS = 'ADD_NON_CONTEXTUAL_NOTIFICATIONS';

export function addNotification(notification, showSnackbar = false, prepend = false) {
    return { type : ADD_NOTIFICATIONS, notification, showSnackbar, prepend };
}

export const ADD_CENTER_NOTIFICATIONS = 'ADD_NON_CONTEXTUAL_CENTER_NOTIFICATIONS';

export function addCenterNotification(notification, prepend = false) {
    return { type : ADD_CENTER_NOTIFICATIONS, notification, prepend };
}

export const SET_CENTER_NOTIFICATIONS = 'SET_CENTER_NOTIFICATIONS';

export function setCenterNotifications(notifications, stopLoading = false) {
    return { type : SET_CENTER_NOTIFICATIONS, notifications, stopLoading };
}

export const SET_NOTIFICATIONS = 'SET_NON_CONTEXTUAL_NOTIFICATIONS';

export function setNotifications(notifications, stopLoading = false) {
    return { type : SET_NOTIFICATIONS, notifications, stopLoading };
}

export const SET_PRE_FETCH_STATUS = 'SET_PRE_FETCH_STATUS';

export function setPreFetchStatus(done = false, error = false) {
    return { type : SET_PRE_FETCH_STATUS, done, error };
}

export const SET_SELECTED_NOTIFICATION = 'SET_SELECTED_NOTIFICATION';

export function setSelectedNotification(selectedNotification, isNotificationCenterOpen, service) {
    let newSelectedNotification;
    if (service && !selectedNotification.readAt) {
        service.markRead(selectedNotification.id);
        newSelectedNotification = { ...selectedNotification, readAt : new Date() };
    } else {
        newSelectedNotification = selectedNotification;
    }
    return { type : SET_SELECTED_NOTIFICATION, selectedNotification : newSelectedNotification, isNotificationCenterOpen };
}

/* ================================ NOTIFICATION - UI ============================================*/

export const SET_NOTIFICATION_BADGE_CONTENT = 'SET_NOTIFICATION_BADGE_CONTENT';

export function setNotificationBadgeContent(content) {
    return { type : SET_NOTIFICATION_BADGE_CONTENT, content };
}
